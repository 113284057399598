import React, { forwardRef, RefObject, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { AgGrid, DockLayout } from '@digitalworkflow/dwreactcommon'
import { ICellRendererParams, IServerSideGetRowsParams } from 'ag-grid-community'
import { jobService } from '@services/index'
import { generateFilterQueryString } from '@utils/generateFilterQuery'
import { generateSortQueryString } from '@utils/generateSortQuery'
import { JobFilterDto } from '@digitalworkflow/dwairportclient'
import moment from 'moment'

import { addFloatBox, getTab, removeRCTab } from '@lib/rc-dock-lib'
import AddEditJob from '@container/jobs/addEditJob/AddEditJob'
import { toast } from 'react-toastify'

import style from './AvailableJobsList.module.scss'

interface IAvailableJobsList {
  dockLayoutRef: RefObject<DockLayout | null>
}

const AvailableJobsList = forwardRef((props: IAvailableJobsList, ref) => {
  const { dockLayoutRef } = props
  const gridRef = useRef<any>(null)

  useImperativeHandle(ref, () => ({
    fetchAvailableJobs
  }))

  const fetchAvailableJobs = () => {
    if (gridRef.current?.api) {
      gridRef.current.api.setServerSideDatasource(dataSource.current)
    }
  }

  const onEditJob = useCallback(
    (job: IJob.Payload) => {
      const id = 'editJob'
      addFloatBox(
        dockLayoutRef,
        id,
        getTab(
          id,
          'Edit Job',
          dockLayoutRef,
          () => (
            <AddEditJob
              job={job}
              handleRemoveAddJob={() => removeRCTab(dockLayoutRef, id)}
              fetchJobs={fetchAvailableJobs}
            />
          ),
          660,
          100,
          1000,
          20
        )
      )
    },
    [dockLayoutRef]
  )

  const handleActiveInActive = useCallback(async (jobData: any) => {
    if (jobData.handlerId) {
      delete jobData._id
      delete jobData.createdAt
      delete jobData.updatedAt
      jobData.status = jobData.status === 1 ? 0 : 1
      const res: any = await jobService.updateJob(jobData.handlerId ?? '', jobData as any)
      if (!res.is_error) {
        toast.success(`Job updated successfully!`)
        fetchAvailableJobs()
        return
      } else {
        toast.error('Something went wrong!')
      }
      if (res.Message) {
        toast.error(res.Message)
      }
    }
  }, [])

  const commonColumnDefs = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Job Name',
        filter: 'agTextColumnFilter',
        minWidth: 250
      },
      {
        field: 'type',
        headerName: 'Type',
        filter: 'agTextColumnFilter'
      },
      {
        field: 'scheduleType',
        headerName: 'Schedule ',
        minWidth: 190,
        autoHeight: true,
        cellRenderer: (_params: ICellRendererParams) => {
          return (
            <div className={`d-flex flex-column ${style.scheduleType}`}>
              <span>{_params.value}</span>
              <span>
                {_params.value === 'daily' ? (
                  moment(_params.data.scheduleValue).tz('America/New_York').format('ha [EST]')
                ) : _params.value === 'frequency' ? (
                  <>
                    Every {_params.data.scheduleValue} {_params.data.scheduleUnit}
                  </>
                ) : (
                  moment(_params.data.scheduleValue).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss [EST]')
                )}
              </span>
            </div>
          )
        }
      },
      {
        field: 'runner',
        headerName: 'Job Runner',
        filter: 'agTextColumnFilter',
        minWidth: 150
      },
      {
        field: 'priority',
        headerName: 'Job Priority',
        filter: 'agTextColumnFilter',
        minWidth: 150
      },
      {
        field: 'client',
        headerName: 'Client',
        filter: 'agTextColumnFilter',
        minWidth: 150
      },
      {
        field: 'status',
        headerName: 'Active',
        minWidth: 120,
        cellRenderer: (_params: ICellRendererParams) => {
          return (
            <div className={`d-flex align-items-center ${style.inputContainer}`}>
              <input
                type='checkbox'
                defaultChecked={_params.data.status === 1}
                className={style.input}
                onChange={() => handleActiveInActive(_params.data)}
              />
            </div>
          )
        }
      },
      {
        field: 'createdBy',
        headerName: 'Created By',
        minWidth: 150
      },
      {
        field: 'createdAt',
        headerName: 'Created At',
        minWidth: 180,
        valueGetter: (params: any) => new Date(params.data.createdAt),
        cellRenderer: (_params: ICellRendererParams) => {
          return <span>{moment(_params.value).format('lll')}</span>
        }
      },
      {
        field: 'id',
        headerName: 'Action',
        resizable: false,
        sortable: false,
        unSortIcon: false,
        floatingFilter: false,
        cellRenderer: (_params: ICellRendererParams) => {
          return (
            <span className={style.edit} onClick={() => onEditJob(_params.data)}>
              Edit
            </span>
          )
        }
      }
    ]
  }, [handleActiveInActive, onEditJob])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 110,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      floatingFilter: true,
      filterParams: {
        maxNumConditions: 1
      }
    }
  }, [])

  const dataSource = useRef({
    getRows: async (params: IServerSideGetRowsParams) => {
      const limit = params.api.paginationGetPageSize() ?? 10
      const page = (params.request.startRow ?? 0) / limit
      const sortString = generateSortQueryString(params.request.sortModel)

      const filterString = generateFilterQueryString(params.request.filterModel)

      const payload: JobFilterDto = {
        sort: sortString || undefined,
        page,
        pageSize: limit,
        ...(filterString ? { filter: filterString } : {})
      }

      const res = await jobService.getJobsByFilters(payload)
      if (res.data?.count === 0) {
        params?.api?.showNoRowsOverlay()
      } else {
        params?.api?.hideOverlay()
      }
      params?.success({
        rowData: res.data?.jobs ?? [],
        rowCount: res.data?.count ?? 0
      })
    }
  })
  const onGridReady = useCallback(
    async (params: any) => {
      params.api.setServerSideDatasource(dataSource.current)
    },
    [dataSource]
  )

  return (
    <div className='job-grid'>
      <div style={{ height: '100%' }}>
        <AgGrid
          gridRef={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={commonColumnDefs}
          rowModelType='serverSide'
          overlayNoRowsTemplate='No data found'
          overlayLoadingTemplate='Loading...'
          pagination
          paginationPageSize={50}
          cacheBlockSize={50}
          animateRows
        />
      </div>
    </div>
  )
})

export default AvailableJobsList
