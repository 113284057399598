import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'

const initialState: IJob.JobsState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  data: []
}

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {}
})

// Action creators are generated for each case reducer function
// export const {} = jobsSlice.actions

export default jobsSlice.reducer
