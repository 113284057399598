import React from 'react'

import style from './index.module.scss'

interface IStatisticCard {
  item: any
  handleClick: () => void
  jobStatus: IJob.Status
}

const StatisticCard = ({ item, handleClick, jobStatus }: IStatisticCard) => {
  return (
    <div
      className={`${style.card} cursor-pointer ${jobStatus === item.key ? style.selectedCard : ''}`}
      style={{ backgroundColor: item.backgroundColor }}
      onClick={handleClick}
    >
      <div className={style.leftBar} style={{ backgroundColor: item.leftSidebarColor }} />
      <div className='d-flex flex-column '>
        <span className={style.text}>{item.title.replace(/ /g, '-')}</span>
        <span className={style.text}>{item.value}</span>
      </div>

      <div className={style.icon} style={{ backgroundColor: item.iconBackgroundColor }}>
        <item.Icon />
      </div>
    </div>
  )
}
export default StatisticCard
