import { PSL } from '@digitalworkflow/psl_web'
import { CommonHelper, PSLHelper } from '@digitalworkflow/dwtranslateclient'

/**
 * Initializes the PSL (Project Specific Library) instance with the provided token.
 *
 * This function performs the following actions:
 * 1. Retrieves the PSL instance.
 * 2. Initializes the local storage for PSL.
 * 3. Sets the project environment for PSL.
 * 4. Sets the Axios authentication key using the provided token.
 *
 * @param token - The authentication token to be used for setting the Axios auth key.
 */
export const initializePSL = async (token: string) => {
  if (process.env.REACT_APP_PSL_ENDPOINT) PSL.SetPSLEndpoint(process.env.REACT_APP_PSL_ENDPOINT)

  const envName = process.env.REACT_APP_ENV || process.env.REACT_APP_ENV || ''
  console.log('Initializing  with Environment: ', process.env.REACT_APP_PSL_ENV)

  PSL.SetProjectName(process.env.REACT_APP_PSL_PROJECT_NAME || 'dw')
  PSL.SetEnvironment(envName)

  const envType = PSL.GetEnvironment()
  CommonHelper.setEnvironment(envType)
  PSL.SetServerAuthKey(`Bearer ${token}`)

  await PSLHelper.initialize()
}
