/* eslint-disable camelcase */
import React, { useCallback, useMemo } from 'react'
import { Spinner, Button, Row, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { Formik } from 'formik'
import FormikInput from '@components/Formik/FormikInput/formikInput'
import FormikSelect from '@components/Formik/formikSelect'
import {
  clientService,
  jobPriorityService,
  jobScheduleService,
  jobService,
  jobTypeService,
  runnerService
} from '@services/index'
import style from './job.module.scss'
import { ScheduledUnit, ScheduledValue } from '@digitalworkflow/dwairportclient/lib/config'
import { validationSchema } from './ValidationSchema'
import moment from 'moment'
import Editor from '@monaco-editor/react'

import 'react-time-picker/dist/TimePicker.css'
import 'react-clock/dist/Clock.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import FormikDatePicker from '@components/Formik/FormikDatePicker'

const initValue: IJob.Payload = {
  name: '',
  type: '',
  scheduleType: ScheduledValue.FREQUENCY,
  scheduleValue: 1,
  runnerUUID: '',
  priority: 'medium',
  scheduleUnit: ScheduledUnit.HOURS,
  client: '',
  payload: {
    type: 'javascript',
    script: '',
    data: '{}'
  }
}

interface IAddEditJobProps {
  handleRemoveAddJob?: () => void
  fetchJobs: () => void
  job?: IJob.Payload
}

const scheduleCustomOption = [
  { value: 'days', label: 'Days' },
  { value: 'hours', label: 'Hours' },
  { value: 'minutes', label: 'Minutes' }
]

const JobPayloadTypeOptions = [
  { value: 'shell', label: 'Shell' },
  { value: 'javascript', label: 'Javascript' },
  { value: 'typescript', label: 'Typescript' },
  { value: 'python', label: 'Python' }
]

const AddEditJob = ({ handleRemoveAddJob, fetchJobs, job }: IAddEditJobProps) => {
  const initialValues = useMemo(() => {
    return {
      ...initValue,
      ...(job ?? {}),
      ...(job?.payload && {
        payload: {
          ...initValue.payload,
          ...(job.payload ?? {}),
          ...(job.payload.data ? { data: JSON.stringify(job.payload.data, undefined, 2) } : {})
        }
      })
    }
  }, [job])

  const onSubmit = useCallback(
    async (values: IJob.Payload) => {
      const { name, type: jobType, scheduleType, scheduleUnit, runnerUUID, priority, client } = values

      let { scheduleValue = 0, payload } = values

      if (scheduleType.toLowerCase() === 'once') {
        scheduleValue = (scheduleValue - moment().utc(true).valueOf()) * 1000
      }

      let res: any
      if (job) {
        res = await jobService.updateJob(job.handlerId ?? '', {
          name,
          type: jobType,
          scheduleType,
          scheduleUnit,
          scheduleValue,
          runnerUUID,
          priority,
          client,
          payload: {
            type: payload.type,
            script: payload.script,
            data: JSON.parse(payload.data)
          }
        })
      } else {
        res = await jobService.createJob({
          name,
          type: jobType,
          scheduleType,
          scheduleUnit,
          scheduleValue,
          runnerUUID,
          priority,
          client,
          payload: {
            type: payload.type,
            script: payload.script,
            data: JSON.parse(payload.data)
          }
        })
      }

      if (!res.is_error) {
        handleRemoveAddJob?.()
        toast.success(`Job ${job ? 'updated' : 'created'} successfully!`)
        fetchJobs()
        return
      } else {
        toast.error(res.Message ?? 'Something went wrong!')
      }
    },
    [fetchJobs, handleRemoveAddJob, job]
  )

  return (
    <>
      <div className={`p-2 ${style.formContainer}`}>
        <div className='d-flex justify-content-end'>
          <Button onClick={handleRemoveAddJob} type='button' color='edit' className='btn-sm'>
            <i className='fa-solid fa-arrow-left me-2 ' />
            Go back to job list
          </Button>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            resetForm,
            handleSubmit,
            setFieldTouched,
            setFieldValue,
            isSubmitting,
            dirty,
            values,
            touched,
            errors,
            isValid
          }) => {
            const handleCancel = () => {
              resetForm()
            }

            const filterPassedTime = (time: any) => {
              const currentDate = moment.utc()
              const selectedDate = moment(time).utc(true)

              return selectedDate.isAfter(currentDate)
            }

            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col md={6}>
                      <FormikInput placeholder='Enter the name of cron job' label='Job name' name='name' />

                      <FormikSelect
                        name='type'
                        placeholder='Select job type'
                        label={
                          <>
                            Select job type &nbsp;
                            <span className='bg-info text-white px-1 rounded'>
                              A placeholder for indicating the type of job
                            </span>
                          </>
                        }
                        className='mb-2 mt-2'
                        valueKey='type'
                        labelKey='type'
                        isOptionLoadFromAPI
                        action={() => jobTypeService.getAllJobTypes()}
                      />

                      <FormikSelect
                        name='scheduleType'
                        placeholder='Select job schedule'
                        label={
                          <>
                            Select job schedule &nbsp;
                            <span className='bg-info text-white px-1 rounded'>It can be either once or frequency</span>
                          </>
                        }
                        className='mb-2'
                        valueKey='type'
                        labelKey='type'
                        isOptionLoadFromAPI
                        handleChange={() => {
                          setFieldValue('scheduleValue', null)
                          setFieldValue('scheduleUnit', '')
                        }}
                        action={() => jobScheduleService.getAllJobSchedules()}
                      />

                      {values.scheduleType?.toLowerCase() === 'once' && (
                        <div className='d-flex flex-column mb-2'>
                          <label className='form-label'>
                            Schedule value &nbsp;
                            <span className='bg-info text-white px-1 rounded'>Select date and time UTC timezone</span>
                          </label>

                          <FormikDatePicker
                            name='scheduleValue'
                            selected={values.scheduleValue ? new Date(values.scheduleValue) : new Date()}
                            onChange={(date: any) => {
                              setFieldTouched('scheduleValue', true)
                              setFieldValue('scheduleValue', new Date(date).getTime())
                            }}
                            showTimeSelect
                            filterTime={filterPassedTime}
                            dateFormat='MM/dd/yyyy h:mm aa'
                            minDate={moment.utc().toDate()}
                          />

                          {touched.scheduleValue && errors.scheduleValue && (
                            <span className='is-invalid'>{errors.scheduleValue}</span>
                          )}
                        </div>
                      )}
                      {values.scheduleType?.toLowerCase() === 'frequency' && (
                        <div className='d-flex gap-1'>
                          <FormikInput
                            errorClassName='d-none'
                            placeholder='Schedule value'
                            type='number'
                            label='Every'
                            name='scheduleValue'
                            style={{
                              height: '38px'
                            }}
                          />
                          <FormikSelect label='Unit' name='scheduleUnit' options={scheduleCustomOption} />
                        </div>
                      )}

                      <FormikSelect
                        name='runnerUUID'
                        placeholder='Select job runner'
                        label='Select job runner'
                        className='mb-2 '
                        valueKey='deviceUUID'
                        labelKey='name'
                        isOptionLoadFromAPI
                        action={() => runnerService.getAllRunners()}
                      />

                      <FormikSelect
                        name='priority'
                        placeholder='Select job priority'
                        label={
                          <>
                            Select job priority &nbsp;
                            <span className='bg-info text-white px-1 rounded'>Priority of the job in the queue</span>
                          </>
                        }
                        className='mb-2'
                        valueKey='level'
                        labelKey='level'
                        isOptionLoadFromAPI
                        action={() => jobPriorityService.getAllJobPriorities()}
                      />

                      <FormikSelect
                        name='client'
                        placeholder='Select client'
                        label={
                          <>
                            Select client &nbsp;
                            <span className='bg-info text-white px-1 rounded'>
                              A placeholder for indicating the client
                            </span>
                          </>
                        }
                        className='mb-2'
                        valueKey='name'
                        labelKey='name'
                        isOptionLoadFromAPI
                        action={() => clientService.getAllClients()}
                      />
                    </Col>
                    <Col md={6}>
                      <FormikSelect
                        name='payload.type'
                        placeholder='Select Job Payload Type'
                        label={
                          <>
                            Payload Type &nbsp;
                            <span className='bg-info text-white px-1 rounded'>
                              A type of payload to be executed by the job runner
                            </span>
                          </>
                        }
                        className='mb-2 '
                        options={JobPayloadTypeOptions}
                      />
                      <label className='form-label'>
                        Payload Script &nbsp;
                        <span className='bg-info text-white px-1 rounded'>
                          A script or shell command to be executed by the job runner
                        </span>
                      </label>
                      <Editor
                        height='200px'
                        language={values.payload.type}
                        defaultValue={values.payload.script}
                        value={values.payload.script}
                        onChange={(value) => {
                          setFieldValue('payload.script', value)
                          setFieldTouched('payload.script', true)
                        }}
                        options={{
                          minimap: { enabled: false },
                          lineNumbers: 'on',
                          fontSize: 14,
                          wordWrap: 'on',
                          wrappingIndent: 'none',
                          tabSize: 2,
                          insertSpaces: true,
                          formatOnType: true,
                          formatOnPaste: true,
                          autoIndent: 'full',
                          autoClosingBrackets: 'always',
                          autoClosingQuotes: 'always',
                          autoClosingComments: 'always',
                          detectIndentation: true
                        }}
                        theme='vs-dark'
                      />
                      <label className='form-label'>
                        Payload Data &nbsp;
                        <span className='bg-info text-white px-1 rounded'>
                          A data to be passed to the script or shell command
                        </span>
                      </label>
                      <Editor
                        height='200px'
                        language={'json'}
                        defaultValue={'{}'}
                        value={values.payload.data}
                        onChange={(value) => {
                          setFieldValue('payload.data', value)
                          setFieldTouched('payload.data', true)
                        }}
                        options={{
                          minimap: { enabled: false },
                          lineNumbers: 'on',
                          fontSize: 14,
                          wordWrap: 'on',
                          wrappingIndent: 'none',
                          tabSize: 2,
                          insertSpaces: true,
                          formatOnType: true,
                          formatOnPaste: true,
                          autoIndent: 'full',
                          autoClosingBrackets: 'always',
                          autoClosingQuotes: 'always',
                          autoClosingComments: 'always',
                          detectIndentation: true
                        }}
                        theme='vs-dark'
                      />
                    </Col>
                  </Row>
                  <Row className='mb-2 mt-3'>
                    <Col className='d-flex gap-2'>
                      <Button type='submit' color='edit' className='btn-sm' disabled={!isValid}>
                        <i className='fal fa-edit me-2' />
                        {job ? 'Update' : 'Submit'}
                        {isSubmitting && <Spinner className='spinner' />}
                      </Button>
                      <Button type='button' color='cancel' className='btn-sm' onClick={handleCancel} disabled={!dirty}>
                        <i className='fa fa-times me-2' />
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </form>
              </>
            )
          }}
        </Formik>
      </div>
    </>
  )
}

export default AddEditJob
