import React from 'react'
import DatePicker, { DatePickerProps } from 'react-datepicker'
import { FormikFormControl } from './FormikFormControl'
import { useField } from 'formik'

export interface FormikDatePickerProps extends PartialBy<DatePickerProps, 'onChange'> {
  rootClassName?: string
  name: string
  touched?: boolean
  errorClass?: string
  onChangeCallback?: (value: any) => void
}

function FormikDatePicker(props: FormikDatePickerProps) {
  const { name, touched = true, errorClass, rootClassName, onChangeCallback, ...rest } = props
  const [field, _meta, helpers] = useField(name)

  return (
    <FormikFormControl className={rootClassName} name={name} touched={touched} errorClass={errorClass}>
      <DatePicker
        name={name}
        {...(rest.selectsMultiple ? { selectedDates: field.value } : { selected: field.value })}
        // eslint-disable-next-line
        onChange={(v: any) => {
          helpers.setTouched(true)
          helpers.setValue(v)
          onChangeCallback?.(v)
        }}
        {...(rest as any)}
      />
    </FormikFormControl>
  )
}

export default FormikDatePicker
