import { SelectDropdown } from '@digitalworkflow/dwreactcommon'
import { useField } from 'formik'
import { get, join, pick, valuesIn } from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'

interface IFormikSelect {
  name: string
  options?: any[]
  placeholder?: string
  label?: React.ReactNode
  isLoading?: boolean
  action?: () => {} // if you want to load options from API pass service function in action attribute
  onChange?: (value: any) => void
  isDisabled?: boolean
  className?: string
  valueKey?: string
  labelKey?: string
  isOptionLoadFromAPI?: boolean
  handleChange?: () => void
  getOptionLabel?: (option: any) => {}
}

const FormikSelect = ({
  name,
  placeholder,
  label,
  onChange,
  options = [],
  isLoading = false,
  isDisabled = false,
  className = '',
  valueKey = '',
  labelKey = '',
  isOptionLoadFromAPI = false,
  action,
  getOptionLabel,
  handleChange
}: IFormikSelect) => {
  const [field, _meta, _helpers] = useField(name)

  const [optionList, setOptionList] = useState<any[]>(options)
  const [isDataLoading, setIsDataloading] = useState<boolean>(isLoading)

  const value = useMemo(() => {
    let _value = null
    if (field.value) {
      _value = optionList.find((item) => item.value.toLowerCase() === field.value.toLowerCase())
    }
    return _value
  }, [field.value, optionList])

  const convertOption =
    (labelKey = 'label', valueKey = 'value') =>
    (option: any) => {
      const result: object = pick(option, labelKey)
      const value = join(valuesIn(result), ' - ')
      return {
        ...option,
        label: getOptionLabel ? getOptionLabel(value) : value,
        value: get(option, valueKey, '')
      }
    }

  useEffect(() => {
    if (isOptionLoadFromAPI) {
      const getOptions = async () => {
        setIsDataloading(true)
        const res: any = await Promise.resolve(action?.())
        setIsDataloading(false)

        if (res) {
          setOptionList(res?.data.map(convertOption(labelKey, valueKey)))
        }
      }
      getOptions()
    }
  }, [isOptionLoadFromAPI])

  return (
    <div className={className}>
      {label && <label className='form-label'>{label}</label>}
      <SelectDropdown
        name='name'
        options={optionList}
        placeholder={placeholder}
        isDisabled={isDisabled}
        onChange={
          onChange ??
          ((value: any) => {
            handleChange?.()
            _helpers.setTouched(true)
            _helpers.setValue(value.value)
          })
        }
        value={value}
        isLoading={isDataLoading}
      />
      {_meta.error && _meta.touched && <span className='is-invalid'>{_meta.error}</span>}
    </div>
  )
}
export default FormikSelect
