import { MenuItem, ProfileMenuItem } from '@digitalworkflow/dwreactcommon'
import { redirectToLogin } from '@utils/redirectToLogin'

/**
 * Array of menu items for the main navigation.
 */
export const menuItems: Array<MenuItem> = [
  {
    item_type: 'Link',
    title_en: 'Job Runs',
    tooltip_en: 'Jobs Runs list dashboard',
    icon: 'fal fa-briefcase',
    route: '/jobs',
    view_groups: 'Everyone'
  },
  {
    item_type: 'Link',
    title_en: 'Available Jobs',
    tooltip_en: 'Available Jobs list dashboard',
    icon: 'fa-solid fa-list-check',
    route: '/available-jobs',
    view_groups: 'Everyone'
  }
]

/**
 * Array of menu items to be displayed in the profile dropdown.
 */
export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: 'Action',
    text_en: 'Logout',
    icon: 'fa fa-power-off',
    action: () => redirectToLogin()
  }
]
