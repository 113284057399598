import React, { useState } from 'react'
import { Tooltip } from 'reactstrap'
import style from './badge.module.scss'

interface IBadge {
  tooltipText?: string
  text: string
  className?: string
}
const Badge = ({ text, tooltipText, className }: IBadge) => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
  const toggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <div>
      <span id='tooltip' className={`${style.tag} ${className}`}>
        {text}
      </span>
      {/* <span id='tooltip' className={`${style.tag} ${errorStatus === 0 ? style.success : style.failed}`}>
        {errorStatus === 0 ? 'SUCCESS' : 'ERROR'}
      </span> */}
      {tooltipText && (
        <Tooltip isOpen={tooltipOpen} target='tooltip' toggle={toggle}>
          {tooltipText}
        </Tooltip>
      )}
    </div>
  )
}
export default Badge
