import { EnvironmentTypes } from '@@types/EnvironmentTypes'

/**
 * Retrieves the current environment type based on the `REACT_APP_ENV` environment variable.
 *
 * The environment variable is converted to lowercase and trimmed of whitespace before comparison.
 *
 * @returns The environment type, which can be one of the following:
 * - `EnvironmentTypes.STAGING` if `REACT_APP_ENV` is 'stg'
 * - `EnvironmentTypes.PRODUCTION` if `REACT_APP_ENV` is 'prod'
 * - `EnvironmentTypes.DEV` if `REACT_APP_ENV` is 'dev' or any other value
 */
export const getEnv = (): EnvironmentTypes => {
  const _env = (process.env.REACT_APP_ENV || '').toLowerCase().trim()

  if (_env === 'stg') return EnvironmentTypes.STAGING
  else if (_env === 'prod') return EnvironmentTypes.PRODUCTION
  else if (_env === 'dev') return EnvironmentTypes.DEV
  else return EnvironmentTypes.DEV
}
