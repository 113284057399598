import { CronJobResponseDto } from '@digitalworkflow/dwairportclient'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Spinner, Badge } from 'reactstrap'
import style from './index.module.scss'
import { jobService } from '@services/index'
import moment from 'moment'
import { toast } from 'react-toastify'
import ConfirmationPopup from '@components/ConfirmationPopup'
import { fetchJobById } from '@store/job/job.slice'
import { useAppDispatch, useAppSelector } from '@store/index'
import { jobSelector } from '@store/job/job.selector'
import { fetchJobRunnerByUUId } from '@store/jobRunner/jobRunner.slice'
import { jobRunnerSelector } from '@store/jobRunner/jobRunner.selector'
import ReactJsonView from '@microlink/react-json-view'
import cls from 'classnames'

export interface ICronJobDetailProps {
  cronJobData: CronJobResponseDto
  jobStatus: string
  fetchCronJob: () => void
  removeCronJob: () => void
  getStatistics: () => void
}

const CronJobDetail = ({ cronJobData, jobStatus, fetchCronJob, removeCronJob, getStatistics }: ICronJobDetailProps) => {
  const jobDetail = useAppSelector(jobSelector)
  const jobRunner = useAppSelector(jobRunnerSelector)
  const [disableLoading, setDisableLoading] = useState<boolean>(false)
  const [openDisablePopup, setOpenDisablePopup] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const d = useAppDispatch()

  useEffect(() => {
    if (!cronJobData.jobHandlerId) {
      return
    }
    getJobDetail(cronJobData.jobHandlerId)
    // eslint-disable-next-line
  }, [cronJobData])

  useEffect(() => {
    if (!jobDetail?.runnerUUID) {
      return
    }
    getJobRunnerDetail(jobDetail.runnerUUID)
    // eslint-disable-next-line
  }, [jobDetail])

  const getJobDetail = useCallback(
    async (handlerId: string) => {
      d(fetchJobById(handlerId))
    },
    [d]
  )

  const getJobRunnerDetail = useCallback(
    async (runnerId: string) => {
      d(fetchJobRunnerByUUId(runnerId))
    },
    [d]
  )

  const duration = useMemo(() => {
    const startTime = moment.utc(cronJobData.startedAt)
    const endTime = moment.utc(cronJobData.endedAt)

    const duration = moment.duration(endTime.diff(startTime))

    // Format the duration as needed
    const parts = [
      duration.days() > 0 && `${duration.days()} day${duration.days() > 1 ? 's' : ''}`,
      duration.hours() > 0 && `${duration.hours()} hour${duration.hours() > 1 ? 's' : ''}`,
      duration.minutes() > 0 && `${duration.minutes()} minute${duration.minutes() > 1 ? 's' : ''}`,
      `${duration.seconds()} second${duration.seconds() > 1 ? 's' : ''}`
    ].filter(Boolean)

    return parts.join(', ')
  }, [cronJobData])

  const handleDisableJob = useCallback(async () => {
    setDisableLoading(true)
    const result = await jobService.cancelJobByHandlerId(cronJobData.jobHandlerId)
    if (!result.is_error) {
      fetchCronJob()
      toast.success('Job disabled successfully')
      removeCronJob()
      getStatistics()
    }
    setDisableLoading(false)
    setOpenDisablePopup(false)
  }, [cronJobData, setDisableLoading, fetchCronJob, setOpenDisablePopup, getStatistics, removeCronJob])

  const startJob = useCallback(async () => {
    setLoading(true)
    const result = await jobService.startJobByHandlerId(cronJobData.jobHandlerId)
    if (!result.is_error) {
      fetchCronJob()
      toast.success('Job disabled successfully')
      getStatistics()
    }
    setLoading(false)
  }, [setLoading, cronJobData.jobHandlerId, fetchCronJob, getStatistics])

  return (
    <div className={`p-2 ${style.cronJobDetail}`}>
      <div className='d-flex justify-content-between flex-wrap gap-2'>
        <div className='d-flex align-items-center gap-2'>
          <span className={style.executionId}>{cronJobData.executionId}</span>
          <div className={style.bdage}>{jobStatus}</div>
        </div>
        {(jobStatus === 'Scheduled' || jobStatus === 'Pending') && (
          <div className='d-flex gap-1'>
            <Button
              type='submit'
              disabled={disableLoading}
              color='cancel'
              className='btn-sm'
              onClick={() => setOpenDisablePopup(true)}
            >
              Disable job
              {disableLoading && <Spinner className='spinner' />}
            </Button>
            <Button type='button' color='add' className='btn-sm' onClick={startJob}>
              Run now
              {loading && <Spinner className='spinner' />}
            </Button>
          </div>
        )}
      </div>
      <div className='d-flex gap-3'>
        <span className={style.label}>Job Name:</span>
        <span className={style.value}>{cronJobData.name}</span>
      </div>

      <h5 className={style.detail}>Detail</h5>
      <div className='mt-2'>
        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Status:</span>
          <span className={`${style.value}`}>
            {cronJobData.error ? (
              <Badge pill color='danger'>
                Error
              </Badge>
            ) : (
              <Badge pill color='success'>
                Success
              </Badge>
            )}
          </span>
        </div>
        {jobStatus === 'Scheduled' || jobStatus === 'Pending' ? (
          <div className='d-flex flex-wrap mb-1'>
            <span className={`${style.label} ${style.detailLabel}`}>Expected Start Time:</span>
            <span className={style.value}>
              {moment.utc(cronJobData.scheduledAt).local(false).format('MMMM Do YYYY, h:mm:ss a')}
            </span>
          </div>
        ) : (
          <>
            <div className='d-flex flex-wrap mb-1'>
              <span className={`${style.label} ${style.detailLabel}`}>Time Started:</span>
              <span className={style.value}>
                {moment.utc(cronJobData.startedAt).local(false).format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            </div>
            {jobStatus !== 'InFlight' && (
              <div className='d-flex flex-wrap mb-1'>
                <span className={`${style.label} ${style.detailLabel}`}>Time Ended:</span>
                <span className={style.value}>
                  {moment.utc(cronJobData.endedAt).local(false).format('MMMM Do YYYY, h:mm:ss a')}
                </span>
              </div>
            )}
            {jobStatus !== 'InFlight' && (
              <div className='d-flex flex-wrap mb-1'>
                <span className={`${style.label} ${style.detailLabel}`}>Duration:</span>
                <span className={style.value}>{duration}</span>
              </div>
            )}
          </>
        )}

        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Runner:</span>
          <span className={`${style.value} ${style.runnerName}`}>{jobRunner?.name ?? 'N/A'}</span>
        </div>

        <div className='d-flex flex-wrap mb-1'>
          <span className={`${style.label} ${style.detailLabel}`}>Handler:</span>
          <span className={style.value}>{cronJobData.jobHandlerId}</span>
        </div>

        {cronJobData.result && (
          <div className='d-flex flex-wrap mb-1'>
            <span className={`${style.label} ${style.detailLabel}`}>Result:</span>
            <div className={cls('d-flex flex-column', style.resultWrapper)}>
              <ReactJsonView
                src={JSON.parse(cronJobData.result)}
                theme='monokai'
                style={{ padding: '10px', borderRadius: '5px' }}
              />
            </div>
          </div>
        )}
      </div>

      <ConfirmationPopup
        open={openDisablePopup}
        setOpen={setOpenDisablePopup}
        headerText='Disable job'
        bodyText='Are you sure, you want to disable this job?'
        handleSuccess={handleDisableJob}
        loading={disableLoading}
      />
    </div>
  )
}

export default CronJobDetail
