import React, { SetStateAction } from 'react'
import { Button, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

interface IConfirmationPopup {
  open: boolean
  setOpen: React.Dispatch<SetStateAction<boolean>>
  headerText: string
  bodyText: string
  handleSuccess: () => void
  loading: boolean
}

const ConfirmationPopup = ({ open, headerText, bodyText, setOpen, loading, handleSuccess }: IConfirmationPopup) => {
  const toggle = () => setOpen(!open)
  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button type='button' color='cancel' className='btn-sm' onClick={toggle}>
          <i className='fa fa-times me-2' />
          No
        </Button>
        <Button color='add' type='button' className='btn-sm' onClick={handleSuccess} disabled={loading}>
          <i className='fa-solid fa-check me-2 ' />
          {loading && <Spinner className='spinner' />}
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  )
}
export default ConfirmationPopup
