import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useRef } from 'react'
import { Button } from 'reactstrap'
import { AgGrid } from '@digitalworkflow/dwreactcommon'
import { ICellRendererParams, IServerSideGetRowsParams, GridReadyEvent } from 'ag-grid-community'
import Badge from '@components/Badge/Badge'
import style from './index.module.scss'
import { initialJobStatistics } from '../JobStatistic/JobStatistic'
import { ReactComponent as EyeIcon } from '@assets/icons/eye.svg'
import { cronJobService } from '@services/index'
import { generateFilterQueryString } from '@utils/generateFilterQuery'
import { generateSortQueryString } from '@utils/generateSortQuery'
import { CronJobNewFilterDTO } from '@digitalworkflow/dwairportclient'
import moment from 'moment'

interface IJobGrid {
  jobStatus: IJob.Status
  handleCronJobDetail: (cronJob: any) => void
}

const JobGrid = forwardRef((props: IJobGrid, ref) => {
  const { jobStatus, handleCronJobDetail } = props
  const gridRef = useRef<any>(null)

  useImperativeHandle(ref, () => ({
    fetchJobs: () => {
      if (gridRef.current?.api) {
        gridRef.current.api.setServerSideDatasource(dataSource.current)
      }
    }
  }))

  const currentItem = useMemo(() => {
    return initialJobStatistics.find((item) => item.key === jobStatus)
  }, [jobStatus])

  const statusClass = useMemo(() => {
    if (jobStatus === 'Scheduled') {
      return style.schedule_status // Scheduled
    } else if (jobStatus === 'Pending') {
      return style.pending_status // Pending
    } else if (jobStatus === 'InFlight') {
      return style.success // In-Flight
    } else {
      return style.success
    }
  }, [jobStatus])

  const commonColumnDefs = useMemo(() => {
    return [
      {
        field: 'executionId',
        headerName: 'Execution ID',
        filter: 'agTextColumnFilter'
      },
      {
        field: 'name',
        headerName: 'Job Name',
        filter: 'agTextColumnFilter',
        minWidth: 250
      },
      {
        field: 'status',
        headerName: 'Status',
        // filter: 'agTextColumnFilter',
        cellRenderer: () => {
          return <Badge text={jobStatus} className={statusClass} />
        }
      },
      ...(jobStatus === 'Landed'
        ? [
            {
              field: 'error',
              headerName: 'Cron Run Status',
              // filter: 'agTextColumnFilter',
              cellRenderer: ({ value }: ICellRendererParams) => {
                return (
                  <Badge
                    text={value === 0 ? 'SUCCESS' : 'ERROR'}
                    className={value === 0 ? style.success : style.failed}
                  />
                )
              }
            }
          ]
        : []),
      ...(jobStatus === 'Scheduled' || jobStatus === 'Pending'
        ? [
            {
              field: 'scheduledAt',
              headerName: 'Expected Start Time',
              filter: 'agDateColumnFilter',
              valueGetter: (params: any) => new Date(params.data.scheduledAt),
              cellRenderer: (_params: ICellRendererParams) => {
                return <span>{moment.utc(_params.value).local(false).format('lll')}</span>
              }
            }
          ]
        : []),
      ...(jobStatus !== 'Scheduled' && jobStatus !== 'Pending'
        ? [
            {
              field: 'startedAt',
              headerName: 'Started On',
              filter: 'agDateColumnFilter',
              valueGetter: (params: any) => new Date(params.data.startedAt),
              cellRenderer: (_params: ICellRendererParams) => {
                return <span>{moment.utc(_params.value).local(false).format('lll')}</span>
              }
            }
          ]
        : []),
      ...(jobStatus !== 'InFlight' && jobStatus !== 'Scheduled' && jobStatus !== 'Pending'
        ? [
            {
              field: 'endedAt',
              headerName: 'Ended On',
              filter: 'agDateColumnFilter',
              valueGetter: (params: any) => new Date(params.data.endedAt),
              cellRenderer: (_params: ICellRendererParams) => {
                return <span>{moment.utc(_params.value).local(false).format('lll')}</span>
              }
            }
          ]
        : []),

      {
        field: 'executionId',
        headerName: 'Action',
        resizable: false,
        sortable: false,
        unSortIcon: false,
        floatingFilter: false,
        cellRenderer: (_params: ICellRendererParams) => {
          return (
            <div className={style.buttonContainer}>
              <Button className={`${style.actionButton} btn btn-sm`} onClick={() => handleCronJobDetail(_params.data)}>
                <EyeIcon />
                <span>View Detail</span>
              </Button>
            </div>
          )
        }
      }
    ]
  }, [jobStatus, handleCronJobDetail, statusClass])

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 110,
      resizable: true,
      sortable: true,
      unSortIcon: true,
      floatingFilter: true,
      filterParams: {
        maxNumConditions: 1
      }
    }
  }, [])

  const dataSource = useRef({
    getRows: async (params: IServerSideGetRowsParams) => {
      const limit = params.api.paginationGetPageSize() ?? 10
      const page = (params.request.startRow ?? 0) / limit
      const sortString = generateSortQueryString(params.request.sortModel)

      const filterString = generateFilterQueryString(params.request.filterModel)

      const payload: CronJobNewFilterDTO = {
        sort: sortString || undefined,
        ...(filterString ? { filter: filterString } : {}),
        page,
        pageSize: limit,
        type: jobStatus
      }

      const res: any = await cronJobService.getCronJobsByNewFilters(payload)
      if (res?.data.cronJobs.length === 0) {
        params?.api?.showNoRowsOverlay()
      } else {
        params?.api?.hideOverlay()
      }
      params?.success({
        rowData: res?.data?.cronJobs ?? [],
        rowCount: res?.data.count ?? 0
      })
    }
  })
  const onGridReady = useCallback(
    async (params: GridReadyEvent<any>) => {
      params.api.setServerSideDatasource(dataSource.current)
    },
    [dataSource]
  )

  return (
    <div className='job-grid'>
      <div className='p-3'>
        <h6 className={style.title}>{currentItem?.title?.replace(/ /g, '-')} jobs</h6>
        <p className={`${style.description} mb-0`}>{currentItem?.description}</p>
      </div>
      <div style={{ height: 'calc(100% - 85px)' }}>
        <AgGrid
          gridRef={gridRef}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          columnDefs={commonColumnDefs}
          rowModelType='serverSide'
          overlayNoRowsTemplate='No data found'
          overlayLoadingTemplate='Loading...'
          pagination
          paginationPageSize={50}
          cacheBlockSize={50}
          animateRows
        />
      </div>
    </div>
  )
})

export default JobGrid
