import React, { InputHTMLAttributes, memo } from 'react'
import cls from 'classnames'
import style from './input.module.scss'

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  className?: string
  inputClassName?: string
  labelClassName?: string
  placeholder?: string
  label?: string
  description?: string
  descriptionClassName?: string
  note?: string
  name: string
  error?: string
  type?: string
  shadow?: boolean
  rightIcon?: React.ReactNode
  touched?: boolean
  containerClass?: string
  variant?: 'normal' | 'solid' | 'outline' | 'base'
  readonly?: boolean
  errorClassName?: string
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      label,
      touched,
      note,
      name,
      error,
      variant = 'normal',
      shadow = false,
      type = 'text',
      inputClassName,
      labelClassName,
      containerClass,
      rightIcon,
      readOnly,
      errorClassName = '',
      description = '',
      descriptionClassName,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={className}>
        {label && (
          <label htmlFor={name} className={`form-label ${labelClassName}`}>
            {label}
          </label>
        )}
        {description && <div className={cls(style.description, descriptionClassName)}>{description}</div>}
        <div className={`relative d-flex align-items-center ${containerClass}`}>
          <input
            id={name}
            name={name}
            type={type}
            ref={ref}
            className={`${style.input} ${error && touched ? 'invalid-feedback' : ''} ${
              readOnly ? 'bg-gray-200 focus:border' : ''
            }`}
            autoComplete='off'
            autoCorrect='off'
            autoCapitalize='off'
            spellCheck='false'
            aria-invalid={error ? 'true' : 'false'}
            readOnly={readOnly}
            {...rest}
          />
          <div className='absolute right-3'>{rightIcon}</div>
        </div>
        {note && <p className='mt-2 text-xs text-body'>{note}</p>}
        {touched && error && <span className={`is-invalid ${errorClassName}`}>{error}</span>}
      </div>
    )
  }
)

export default memo(Input)
