import { authServiceInstance } from './authServiceInstance'
import { LocalSettings } from './LocalSettings'

/**
 * Clears the login data by performing the following actions:
 * - Logs out the user using the authServiceInstance.
 * - Removes the portal authentication token from local settings.
 * - Removes the portal user information from local settings.
 */
export const clearLoginData = () => {
  authServiceInstance.authLogout()
  LocalSettings.removePortalAuthToken()
  LocalSettings.removePortalUser()
}
