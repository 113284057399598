import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
  name: Yup.string().trim().required('Job name is required'),
  type: Yup.string().trim().required('Job type is required'),
  scheduleType: Yup.string().trim().required('Job schedule type is required'),
  scheduleUnit: Yup.string()
    .trim()
    .when('scheduleType', (scheduleType: any, schema) => {
      return scheduleType?.[0] === 'Frequency' ? schema.required('Schedule Unit is required') : schema.nullable()
    }),
  scheduleValue: Yup.number().required('Job schedule value is required'),
  runnerUUID: Yup.string().trim().required('Job runner is required'),
  priority: Yup.string().trim().required('Job priority is required'),
  client: Yup.string().trim().required('Client is required'),
  payload: Yup.object().shape({
    type: Yup.string().trim().required('Payload type is required'),
    script: Yup.string().trim().required('Payload value is required  '),
    data: Yup.string().trim().required('Payload data is required')
  })
})
