import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { jobService } from '@services/index'

const initialState: IJob.JobState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  data: undefined
}

export const fetchJobById = createAsyncThunk('job/fetchById', async (handlerId: string, _thunkAPI) => {
  const result = await jobService.getJobByHandlerId(handlerId)
  return result
})

export const jobSlice = createSlice({
  name: 'job',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchJobById.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload?.data as any
      state.error = action.payload?.is_error ? 'Error fetching job' : null
    })
    builder.addCase(fetchJobById.pending, (state) => {
      state.isLoading = true
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = jobSlice.actions

export default jobSlice.reducer
