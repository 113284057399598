import { clearLoginData } from './clearLoginData'
import { getLoginPortalHost } from './getLoginPortalHost'
import { getLoginPortalID } from './getLoginPortalID'

/**
 * Redirects the user to the login portal.
 *
 * This function clears any existing login data and then redirects the user
 * to the login portal URL. The URL is constructed using the login portal host
 * and ID, with the current hostname appended as a return parameter.
 *
 * @returns
 */
export const redirectToLogin = () => {
  clearLoginData()

  console.log('Redirecting to Login Portal')
  window.location.href = `${getLoginPortalHost()}/login/${getLoginPortalID()}?return=` + window.location.hostname
}
