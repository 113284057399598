import { configureStore } from '@reduxjs/toolkit'
import { jobSlice } from './job/job.slice'
import { jobsSlice } from './jobs/jobs.slice'
import { useDispatch, useSelector } from 'react-redux'
import logger from 'redux-logger'
import { jobRunnerSlice } from './jobRunner/jobRunner.slice'

export const store = configureStore({
  reducer: {
    job: jobSlice.reducer,
    jobs: jobsSlice.reducer,
    jobRunner: jobRunnerSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()

export const useAppSelector = useSelector.withTypes<RootState>()
