import React, { useCallback, useRef } from 'react'
import { RCDockLayout, DockLayout } from '@digitalworkflow/dwreactcommon'
import { getSize } from '@utils/getSize'
import { Button } from 'reactstrap'
import { addFloatBox, getTab, removeRCTab } from '@lib/rc-dock-lib'
import AvailableJobsList from '@container/availableJobs/AvailableJobsList'
import AddEditJob from '@container/jobs/addEditJob/AddEditJob'

const AvailableJobs = () => {
  const dockLayoutRef = useRef<DockLayout | null>(null)
  const jobListRef = useRef<any>()
  const fetchJobs = useCallback(() => {
    jobListRef.current?.fetchAvailableJobs()
  }, [jobListRef])

  const handleAddNewJob = () => {
    const id = 'addJob'
    addFloatBox(
      dockLayoutRef,
      id,
      getTab(
        id,
        'Add New Job',
        dockLayoutRef,
        () => <AddEditJob handleRemoveAddJob={() => removeRCTab(dockLayoutRef, id)} fetchJobs={fetchJobs} />,
        660,
        100,
        1000,
        20
      )
    )
  }

  const defaultLayout: any = {
    dockbox: {
      children: [
        {
          mode: 'vertical',
          children: [
            {
              size: getSize(1500, 600),
              tabs: [
                {
                  id: 'availableJob',
                  title: 'Jobs',
                  content: (
                    <div className='p-2'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <h6>Available Jobs</h6>
                        <Button type='button' color='add' className='btn-sm' onClick={handleAddNewJob}>
                          Add New Job
                        </Button>
                      </div>
                    </div>
                  ),
                  group: 'headless'
                }
              ]
            },
            {
              tabs: [
                {
                  id: 'availableJobList',
                  title: 'Available Jobs',
                  content: <AvailableJobsList dockLayoutRef={dockLayoutRef} ref={jobListRef} />,
                  group: 'headless'
                }
              ],
              group: 'close-all'
            }
          ]
        }
      ]
    },
    floatbox: {
      children: []
    }
  }

  return (
    <div className='mt-2'>
      <RCDockLayout dockLayoutRef={dockLayoutRef} defaultLayout={defaultLayout} />
    </div>
  )
}
export default AvailableJobs
