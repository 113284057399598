import { EnvironmentTypes } from '@@types/EnvironmentTypes'
import { getEnv } from './getEnv'
import { loginDevUrl, loginProdUrl, loginStagingUrl } from '@constants/urls'

/**
 * Retrieves the login portal host URL based on the current environment.
 *
 * @returns The login portal URL corresponding to the current environment.
 *
 * The function determines the environment by calling `getEnv()` and returns the appropriate
 * login URL based on the environment type:
 * - `EnvironmentTypes.STAGING`: Returns the staging login URL.
 * - `EnvironmentTypes.PRODUCTION`: Returns the production login URL.
 * - Default: Returns the development login URL.
 */
export const getLoginPortalHost = () => {
  const env = getEnv()

  switch (env) {
    case EnvironmentTypes.STAGING:
      return loginStagingUrl
    case EnvironmentTypes.PRODUCTION:
      return loginProdUrl
    default:
      return loginDevUrl
  }
}
