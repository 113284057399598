import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { runnerService } from '@services/index'

const initialState: IJobRunner.JobRunnerState = {
  isLoading: false,
  isCreating: false,
  isUpdating: false,
  isDeleting: false,
  data: undefined
}

export const fetchJobRunnerByUUId = createAsyncThunk('jobRunner/fetchByUUID', async (runnerId: string, _thunkAPI) => {
  const result = await runnerService.getRunnerByUUID(runnerId)
  return result
})

export const jobRunnerSlice = createSlice({
  name: 'jobRunner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchJobRunnerByUUId.fulfilled, (state, action) => {
      // Add user to the state array
      state.data = action.payload?.data as any
      state.error = action.payload?.is_error ? 'Error fetching job runner by uuid' : null
    })
    builder.addCase(fetchJobRunnerByUUId.pending, (state) => {
      state.isLoading = true
    })
  }
})

export default jobRunnerSlice.reducer
